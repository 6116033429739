import { defineRule, configure } from "vee-validate";
import { localize } from '@vee-validate/i18n'
import {
  alpha,
  alpha_num,
  email,
  required,
  min,
  max,
  confirmed,
  digits,
  numeric,
  alpha_spaces,
  max_value,
  min_value,
  between,
  regex,
} from "@vee-validate/rules";

defineRule("alpha", alpha);
defineRule("alpha_num", alpha_num);
defineRule("email", email);
defineRule("required", required);
defineRule("min", min);
defineRule("max", max);
defineRule("confirmed", confirmed);
defineRule("digits", digits);
defineRule("numeric", numeric);
defineRule("alpha_spaces", alpha_spaces);
defineRule("max_value", max_value);
defineRule("min_value", min_value);
defineRule("between", between);
defineRule("regex", regex);

configure({
  // Configure custom validator error messages.
  generateMessage: localize('en', {
      messages: {
          email:(ctx) => "The :attribute must be a valid email address.".replace(':attribute',ctx.field),
          required:(ctx) => "The :attribute field is required.".replace(':attribute',ctx.field),
          
      },
  }),
});
