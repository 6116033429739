import {defineStore} from "pinia";
import {reactive} from "vue";
import {i18n} from "../main.js";
import axios from "axios";
import { API_URL } from "./constants.js";

export const useLangStore = defineStore("lang", ()=> {

    let saved_lang_code = localStorage.getItem("lang") ?? "en"

    // if lang and lang_labels both preset in local Storage, then Instant load before XHR,
    // then fetch labels to get updated labels.
    if(localStorage.getItem("lang") && localStorage.getItem("lang_labels")) {
      i18n.global.setLocaleMessage(localStorage.getItem("lang"), JSON.parse(localStorage.getItem("lang_labels")));
      i18n.global.locale = localStorage.getItem("lang");
    }

    // ------------ State --------------
    const state = reactive({
        isLoading : true,
        selected_language : saved_lang_code 
    })

    // All Supported Languages
    const languages = [
        {
            language : "English",
            locale : "en"
        },
        {
            language : "Spanish",
            locale : "es"
        }
    ];

    function loadLabels(lang_code = "en") {
        let url = "applabels";
        let config = {
          headers: {
            "Accept-Language": lang_code
          }
        }
        state.isLoading = true;
        axios.get(API_URL + url, config).then(response => {
          if (response.status == 200) {
            i18n.global.setLocaleMessage(lang_code, response.data.data);
            i18n.global.locale = lang_code;

            localStorage.setItem("lang_labels", JSON.stringify(response.data.data)); // Save labels to localstorage
            
          }
          state.isLoading = false
        }).catch(e => {
          console.log("Lang Store, loadLables error ", e);
          state.isLoading = false
        })
    }
    loadLabels(saved_lang_code);


    function setLocale(lang_code) {

        loadLabels(lang_code);
        localStorage.setItem("lang" , lang_code);
        state.selected_language = lang_code;

    }

    return {
        state,
        setLocale,
        languages
    }
})