import { ref, onMounted } from "vue";
import { BG_FILTER } from "@/stores/constants";

// Composable definition
export function useBackgroundEffects() {
    // Constants
	const effects = [
		{ key: 'none', label: 'None', },
		{ key: 'soft-blur', label: 'Soft blur' },
		{ key: 'strong-blur', label: 'Strong blur' },
		{ key: 'coffee-shop', label: 'Coffee Shop' },
		{ key: 'forest', label: 'Forest' },
		{ key: 'hills', label: 'Hills' },
		{ key: 'library', label: 'Library' },
		{ key: 'lounge', label: 'Lounge' },
		{ key: 'ocean', label: 'Ocean' },
		{ key: 'office', label: 'office' },
		{ key: 'roller-coaster', label: 'roller coaster' },
		{ key: 'palms', label: 'palms' },

	];

    // state definitions
    const currentEffect = ref(localStorage.getItem(BG_FILTER) ? JSON.parse(localStorage.getItem(BG_FILTER)) : effects[0]);
	
	// lifecycle hooks
    onMounted(() => {
		/* const savedEffect = localStorage.getItem(BG_FILTER) ? JSON.parse(localStorage.getItem(BG_FILTER)) : effects[0];
		(async () => {
			// artifical delay to wait for the video source to be ready
			await new Promise(resolve => setTimeout(resolve, 100));
			await selectEffect({ key: savedEffect.type }, savedEffect.config.source);
		})() */
		
	})

	/** Functions - starts */

    async function selectEffect(newEffect, imageUrl = '') {
    	currentEffect.value = newEffect;

    	if (!['none', 'soft-blur', 'strong-blur'].includes(newEffect.key)) {
    		await setBackgroundImage(imageUrl);
    	}

    	if (newEffect.key.endsWith("blur")) {
    		await setBackgroundBlur(newEffect);
    	}

    	if (newEffect.key === "none") {
    		await resetBackground();
    	}
    }

    async function setBackgroundImage(imageUrl) {
    	if (imageUrl) {
    		const callObj = window.dailyCo;
			const processor = {
				type: "background-image",
				config: { source: imageUrl }
			};
    		await callObj.updateInputSettings({ video: { processor } });
			localStorage.setItem(BG_FILTER, JSON.stringify(processor));
    	}
    }

    async function resetBackground() {
    	const callObj = window.dailyCo;
		const processor = {
			type: "none",
		}
    	await callObj.updateInputSettings({ video: { processor } });
		localStorage.setItem(BG_FILTER, JSON.stringify(processor));
    }

    async function setBackgroundBlur(effect) {
    	let blurLevel = 0;

    	if (effect.key === 'soft-blur') {
    		blurLevel = 0.3;
    	} else {
    		blurLevel = 1;
    	}
		const processor = {
			type: "background-blur",
			config: { strength: blurLevel }
		};

    	const callObj = window.dailyCo;
    	await callObj.updateInputSettings({ video: { processor } });
		localStorage.setItem(BG_FILTER, JSON.stringify(processor));
    }

    /** Functions - ends */


    // Exposing state and state setters
    return { currentEffect, effects, selectEffect };
}