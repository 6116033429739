<template>
    <div class="waves" ref="wavesRef">
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
    </div>
</template>

<script setup>
// top level imports
import { watch, ref } from "vue";

// Refs
const wavesRef = ref(null);

// Props definition
const props = defineProps({ animating: { type: Boolean, default: false } });

// Watchers
watch(() => props.animating, () => {
    if (props.animating) {
        startWavesAnimation();
    } else {
        stopWavesAnimation();
    }
})

/** Handler / Utility functions - starts */
const startWavesAnimation = () => {
    const children = [...wavesRef.value.children];
    children[0].classList.add('wave1')
    children[1].classList.add('wave2')
    children[2].classList.add('wave3')
    children[3].classList.add('wave4')
    children[4].classList.add('wave5')
}

const stopWavesAnimation = () => {
    const children = [...wavesRef.value.children];
    children[0].classList.remove('wave1')
    children[1].classList.remove('wave2')
    children[2].classList.remove('wave3')
    children[3].classList.remove('wave4')
    children[4].classList.remove('wave5')
}
/** Handler / Utility functions - ends */

</script>

<style scoped>
.waves {
  display: flex;
  justify-content: space-between;
  margin-top: -0.5rem;
  height: 2rem;
  --boxSize: 6px;
  --gutter: 3px;
  width: calc((var(--boxSize) + var(--gutter)) * 5);
}

.wave {
  transform: scaleY(.4);
  height: 100%;
  width: var(--boxSize);
  background: #12E2DC;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 8px;
}

.wave1, .wave3, .wave5 {
  animation-name: quiet;
}

.wave2 {
  animation-name: normal;
}

.wave4 {
  animation-name: loud;
}

@keyframes quiet {
  25%{
    transform: scaleY(.6);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(.8);
  }
}

@keyframes normal {
  25%{
    transform: scaleY(1);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(.6);
  }
}
@keyframes loud {
  25%{
    transform: scaleY(1);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(1.2);
  }
}
</style>