<template>
  <div class="d-flex justify-content-center align-items-center m-2">
    <!-- <label class="me-2">{{ $t("select_language")}}</label> -->
    <select
      @change="setLanguage($event.target.value)"
      class="form-select form-select-sm"
      style="width:7rem"
      placeholder="Language"
    >
      <option
        :key="item.language"
        v-for="item in $langStore.languages"
        :selected="item.locale==$langStore.state.selected_language"
        :value="item.locale"
      >
        {{ item.language }}
      </option>
    </select>  
  </div>
  
</template>

<script type="text/javascript">
  export default {
    name: "LangSelect",

    methods: {
      setLanguage(lang_code) {
        this.$langStore.setLocale(lang_code);
      }
    }
  }
</script>