import {SERVER_URL} from "../stores/constants";

import { io } from "socket.io-client";

export let socketio = null;

let isConnected = false;

export function init(vueAppInstance) {
    const jwtToken = localStorage.getItem("jwtToken");
    if(!socketio) {
        socketio = io(SERVER_URL, {
            auth: {
                token: jwtToken ?? undefined, 
            },
            transports: ['websocket'],
            autoConnect: false
        });
        socketio.on("connect", () => {
            console.log("WS is connected.")
        })
        socketio.on("disconnect", () => {
            console.warn("WS is DISCONNECTED.")
        })

        socketio.on("auth_error", (error) => {
            console.error("WS Authentication Error:", error);
        });

        socketio.on("connect_error", (error) => {
            console.error("WS Connection Error:", error.message);
            
        });

        socketio.on("connect_timeout", (timeout) => {
            console.warn("WS Connection Timeout:", timeout);
        });
    }
    if(vueAppInstance && vueAppInstance.config) {
        vueAppInstance.config.globalProperties.$socket = socketio;
    }
}

export function connect() {
    console.log("connecting WS client");
    if(!isConnected) {
        socketio.connect();
        isConnected = true
    }
}

export function disconnect() {
    if (socketio) {
        socketio.disconnect();
        isConnected = false
    }
}