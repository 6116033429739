<template>
	<div class="w-100">
		<div class="mb-2">
		    <video-tile
		        :participant="participant"
		        :hide-leave-call="true"
		        :hide-screen-share="true"
		        :independent="true"
		        :fallback-username="userName"
		    />
		</div>

		<div class="d-flex justify-content-between my-1">
			<span class="first-letter-capitalize">{{ $t("select_effect") }}</span>
		 	<strong class="text-capitalize">{{ currentEffect?.label }} <span class="text-capitalize">{{ $t("applied") }}</span></strong>
		</div>

		<div class="d-flex flex-wrap justify-content-center">
			<div
				v-for="effect in effects"
				:key="effect.key" class="me-1 mb-1"
				:class="{ 'border border-2 border-primary': currentEffect?.key === effect.key }"
			>
				<button
					class="btn btn-effect"
					:title="effect.label" @click="() => selectEffect(effect, getImageUrl(effect.key))"
				>
					<i
						v-if="['none', 'soft-blur', 'strong-blur'].includes(effect.key)"
						class="ti fs-1"
						:class="{
							'ti-photo': ['soft-blur', 'strong-blur'].includes(effect.key),
							'ti-user': effect.key === 'none',
							'soft-blur': effect.key === 'soft-blur',
							'strong-blur': effect.key === 'strong-blur',
						}"
					/>
					<img v-else height="75" width="75" :src="getImageUrl(effect.key)" :alt="effect.label" />
				</button>
			</div>
		</div>
	</div>
</template>

<script setup>
	// top level imports
	import { useBackgroundEffects } from "@/composables/useBackgroundEffects";

	// Components
	import VideoTile from "@/components/Dailyco/VideoTile.vue";

    // Composable usage
    const { effects, currentEffect, selectEffect } = useBackgroundEffects();

	// Props definition
	defineProps({
		participant: Object,
		userName: String
	})

	/** Lifecycle hooks - starts */

	function getImageUrl(name, ext = 'jpg') {
		const url = new URL(`/backgrounds/${name}.${ext}`, window.location.origin).href;
  		return url;
	}

	/** Lifecycle hooks - ends */


	/** Handler / Utility functions - starts */

	/** Handler / Utility functions - ends */
</script>

<style type="text/css" scoped>
	.btn-effect {
		height: 75px;
		width: 75px;
	}

	.soft-blur {
		filter: blur(1px);
	}

	.strong-blur {
		filter: blur(3px);
	}
</style>