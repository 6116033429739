<template>
  <div class="no-video">
    <ParticipantName :participant="participant" :fallback-username="fallbackUsername" />
  </div>
</template>

<script>
import ParticipantName from "@/components/Dailyco/ParticipantName.vue";

export default {
  name: "NoVideoTile",
  components: { ParticipantName }, 
  props: ["participant", "fallbackUsername"],
};
</script>

<style>
.no-video {
  background-color: #2b3f56;
  border-radius: 16px;
  color: #fff;
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 2px 0;
}
</style>
