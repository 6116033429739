<template>
    <span class="form-label" role="button" @click="playTestSound">
        <div class="spinner-grow spinner-grow-sm" role="status" v-if="testSoundPlaying">
          <span class="visually-hidden">Loading...</span>
      </div>
      <span class="first-letter-capitalize">{{ $t("play_test_sound") }}</span>
  </span>

  <audio ref="testSoundEl" src="/audio/test-sound.mp3" @ended="testSoundEnded" />
</template>

<script setup>
    // top level imports
    import { ref } from "vue";

    // state definition
    const testSoundPlaying = ref(false);

    // Refs
    const testSoundEl = ref(null);

    /** Handler / Utility functions  */
    const playTestSound = () => {
        testSoundPlaying.value = true;
        testSoundEl.value?.play();
    }

    const testSoundEnded = () => {
        testSoundPlaying.value = false;
    }
</script>