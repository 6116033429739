import { defineStore } from "pinia";
import {inject, reactive, watch} from "vue"
import axios from "axios";
import { API_URL } from "./constants";
export const useCommonStore = defineStore("common" , () => {


    let user = localStorage.getItem("user");
    let jwtToken = localStorage.getItem("jwtToken");
    let isLoggedIn = localStorage.getItem("isLoggedIn");

    let eventBus = inject("eventBus");
    
    // ---------- State -----------
    const state = reactive({
        user : user ? JSON.parse(user) : {},
        jwtToken : jwtToken ? jwtToken : null,
        isLoggedIn : isLoggedIn ? true : false,
        permissions : [],
        config : {}

    });

    // ---------- Setters -----------
    function setUser(details) {
        state.user = details
        saveToLocalStorage()
    }
    function setToken(jwt_token) {
        state.jwtToken = jwt_token;
        state.isLoggedIn = true;
        saveToLocalStorage()
    }

    // ---------- actions -----------
    function clearStore() { // for logout.
        localStorage.removeItem("user");
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("isLoggedIn");
    }


    // ---------- Methods ----------
    (function (){ // getUserPermission 

        if(state.jwtToken == null || state.jwtToken == "") return; // prevent loading permissions for unAuthenticated users.

        let url = API_URL + "user/permissions";
        axios.get(url,{
            headers: {
                'Authorization': state.jwtToken,
                'Practice-Code': 'DEMO'
            }
        }).then(response => {
            if(response.status == 200) {
                state.permissions = response.data.data;
            }
            
        }).catch(e => {
            if(e.response.status == 401) {
                clearStore();
                window.location.replace("/login?timeout=1")
            }
        })
        
    })();

    (function () { // getConfigs

        let url = API_URL + "config/master";
        axios.get(url).then(response => {
            if(response.status == 200 && response.data.error == false) {
                response.data.data.forEach(item => {
                    state.config[item.key] = item.value;
                })
                eventBus.emit("master-configs-loaded");
            }
        }).catch(e => {
            
        })

    })()

    function saveToLocalStorage() { 
        // save only required variables to localStorage.
        localStorage.setItem("user", JSON.stringify(state.user));
        localStorage.setItem("jwtToken", state.jwtToken);
        localStorage.setItem("isLoggedIn", state.isLoggedIn)
    }

    function getAccessLevel(permission) {
        let access_level = "no_access";
        state.permissions.forEach(item => {
            if(item.permission == permission) {
                access_level = item.access_level;
            }
        })
        return access_level;
    }

    function hasPermission(permission, level) {
        const accessLevel = getAccessLevel(permission);

        if (level === "view_manage") {
            return accessLevel === "view_manage";
        }
        else if (level === "view") {
            return accessLevel === "view_manage" || accessLevel === "view";
        }
        else if (level === "no_access") {
            return accessLevel === "no_access";
        } 
        else {
            return false;
        }
    }    
    
    return {
        state,
        setUser,
        setToken,
        clearStore,
        getAccessLevel,
        hasPermission
    }
})