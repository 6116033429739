<template>
	<div v-if="!supportsMediaRecorder">Browser doesn't supoort recording</div>

	<span
	    :class="`form-label ${participant?.audio ? '' : 'text-muted'}`"
	    role="button"
	    @click="handleMicTest"
	    title="Unmute to test your mic"
	    v-if="recordStatus === 'idle'"
	>
	    {{ $t("test_your_mic") }}
	</span>
	<div class="d-flex align-items-center" v-if="recordStatus === 'recording'">
		<!-- <small class="badge badge-center rounded-pill text-small bg-danger recorder me-1">&nbsp;</small> -->
		<Waves :animating="micVolume >= 1" />
		<!-- <button class="stop-recording-btn text-white" @click="stopRecording">Stop</button> -->
	</div>

	<div class="d-flex" v-if="['playing', 'ended'].includes(recordStatus)">
		<i
			:class="`ti ti-volume me-1 ${recordStatus === 'playing' ? 'text-success' : ''}`"
			role="button" title="Play recorded audio"
			@click="playRecording"
		/>
		<i class="ti ti-x" role="button" @click="resetRecording" title="Reset" />
	</div>

	<audio
		ref="audioRef"
		autoplay
		@ended="handleAudioEnded"
		@play="handlePlay"
	/>
</template>

<script>
    // top level imports

    // constituent components
    import Waves from "@/components/common/Waves.vue";

    // Component definition
	export default {
		name: "MicTesting",

        components: { Waves },

		props: {
			participant: Object
		},

		data() {
			return {
				recordStatus: 'idle',
				supportsMediaRecorder: false,
				mediaRecorder: null,
                audioContext: null,
                micVolume: 0
			}
		},

		mounted() {
			this.supportsMediaRecorder = MediaRecorder && (
				MediaRecorder.isTypeSupported('audio/mp4') || MediaRecorder.isTypeSupported('audio/webm'));
		},

		methods: {
			handleMicTest() {
				if (!this.participant.audio) return;

				try {
					// Initializing
					const MAX_RECORD_TIME = 5000;
					if (this.supportsMediaRecorder) {
						this.mediaRecorder = new MediaRecorder(new MediaStream([this.participant?.audioTrack]));
					}
					const mediaRecorder = this.mediaRecorder;
					const audioEl = this.$refs.audioRef;

					if (!mediaRecorder) throw new Error("Media Recorder not initialized");

					// start recorder
					mediaRecorder?.start();

					const audioChunks = [];
					// const start = Date.now();

					// Register listeners on recorder
					mediaRecorder?.addEventListener('dataavailable', (ev) => {
						audioChunks.push(ev.data);
					});

					const timeout = setTimeout(() => {
						if (mediaRecorder?.state === 'recording') {
							mediaRecorder?.stop();
						}
					}, MAX_RECORD_TIME);

					mediaRecorder?.addEventListener('stop', () => {
						// console.log("media recorder stopped")
						clearTimeout(timeout);
						const blob =
						  // Safari only returns one blob when recording stopped
						  audioChunks.length === 1 && audioChunks[0] instanceof Blob
						    ? audioChunks[0]
						    : new Blob(audioChunks);
						const audioUrl = URL.createObjectURL(blob);
						const end = Date.now();
						// this.duration = (end - start) / 1000;
						if (!audioEl) return;
						audioEl.src = audioUrl;
						audioEl.play().catch(() => {
						  this.recordStatus = "ended";
						});
                        this.audioContext?.close();
                        this.audioContext = null;
					});

					this.recordStatus = "recording";

                    this.initAudioAnalyser();

				} catch (e) {
					console.error(e.toString());
				}
			},

			handleAudioEnded() {
				// console.log("audio play ended");
				this.recordStatus = "ended";
			},

			handlePlay() {
				// console.log("audio play started");
				this.recordStatus = "playing";
			},

			stopRecording() {
				this.mediaRecorder.stop();
				this.recordStatus = "ended";
			},

			resetRecording() {
				const audioEl = this.$refs.audioRef;
				audioEl.pause();
				audioEl.src = null;
				this.recordStatus = "idle";
			},

			playRecording() {
				const audioEl = this.$refs.audioRef;
				audioEl.currentTime = 0;
				audioEl.play();
			},

            async initAudioAnalyser() {
                const audioStream = new MediaStream([this.participant?.audioTrack]);

                // Initialize audio context
                this.audioContext = new AudioContext();
                const audioContext = this.audioContext;

                // Getting a reference to MediaStreamAudioSourceNode object
                const microphone = audioContext.createMediaStreamSource(audioStream);

                // Loading the AudioWorkletProcessor
                // from another script with addModule method
                await audioContext.audioWorklet.addModule('/volume-processor.js');

                // Initialising an AudioWorkletNode representing the
                // characteristics and functionalities of the loaded
                // AudioWorkletProcessor
                const audioNode = new AudioWorkletNode(audioContext, 'vumeter');

                // Start listening to the messages sent from the AudioWorkletProcessor node
                audioNode.port.onmessage = event => {
                    this.micVolume = event.data.volume * 100;
                }

                // Bind the MediaStreamSourceNode to the AudioWorkletProcessor node
                // to facilitate processing of the data
                microphone.connect(audioNode).connect(audioContext.destination);
            }
		}
	}
</script>

<style scoped>
	.stop-recording-btn {
		height: 1.2rem;
		font-size: 0.8rem;
		animation-duration: 5000ms;
		animation-name: fill-left-to-right;
		animation-timing-function: linear;
		animation-fill-mode: forwards;
		animation-iteration-count: 1;
		animation-direction: normal;
		animation-play-state: running;
		background: linear-gradient(90deg,#1bebb9 0%,#1bebb9 50%,#121a24 50%,#121a24 100%);
	}

	@keyframes fill-left-to-right {
		from {
	        background-position: 100% 0%;
		}
	    to {
	       background-position: 0% 0%;
	    }
	}
</style>