<template>
	<Modal id="device-permissions-info">
		<template v-slot:header>
			<h1 class="modal-title fs-4 text-center">{{ title }}!</h1>	
		</template>

		<div class="row">
			<div class="col-md-4 col-xs-12">
				<img src="/device_permissions.svg" class="img-fluid" />
			</div>

			<div class="col-md-8 col-xs-12 d-flex align-items-center">
				<ol class="fs-5 text-start">
					<li class="mb-2">{{ $t('click_icon_in_address_bar') }}.</li>
					<li>{{ $t('turn_on_permissions_to_allow_access') }}.</li>
				</ol>
			</div>
		</div>
	</Modal>
</template>

<script type="text/javascript" setup>
	// top level imports
	import { computed, inject } from "vue";

	// Components
	import Modal from "@/components/common/Modal.vue";

	// Props definition
	const props = defineProps({
		cameraBlocked: { type: Boolean, default: false },
		micBlocked: { type: Boolean, default: false }
	});

	// Global vars
	const t = inject('t');

	// Computed Props
	const title = computed(() => {
		let text = "";

		if (props.cameraBlocked) text = t('your') + ' ' + t('camera');
		if (props.micBlocked) text = t('your') + ' ' + t('microphone');
		if (props.cameraBlocked && props.micBlocked) {
			text = `${t('your')} ${t('camera')} ${t('and')} ${t('microphone')}`;
		}

		return text + ' ' + t('is_disabled');
	});
</script>