import './assets/main.css'
import 'sweetalert2/src/sweetalert2.scss'

import daily from "@daily-co/daily-js";

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router';
import "./js/validation";
import axios from "axios"
import { API_URL } from './stores/constants';
import * as Sentry from "@sentry/vue";
import mitt from 'mitt';
import {useCommonStore} from "./stores/common.js"
import { createI18n } from 'vue-i18n';
import { useLangStore } from './stores/lang.js';
import {init} from "./js/socketio";


window.dailyCo = daily.createCallObject();

const app = createApp(App)

init(app);
app.use(createPinia())
app.use(router)

// Initialize Stores
const eventBus = mitt();
app.config.globalProperties.$eventBus = eventBus;
app.provide('eventBus', eventBus);

let commonStore = useCommonStore();
app.config.globalProperties.$commonStore = commonStore;

// Lang Configuration
export const i18n = createI18n({
    locale: 'en', 
    messages: {},
    missing: (locale, key) => {
        // to remove labels key not found warning in console.
        return key;
      },
  });
app.use(i18n);
let langStore = useLangStore();
app.config.globalProperties.$langStore = langStore;
app.config.globalProperties.$t = i18n.global.t;
app.provide('t', i18n.global.t)

// Global axios Configuration.
const axios_global = axios.create({
    baseURL: API_URL
});
axios_global.interceptors.request.use(config => {
    const token = localStorage.getItem("jwtToken");
    const lang_code = langStore.state.selected_language;
    if (token) {
        config.headers.Authorization = `${token}`;
        config.headers["Accept-Language"] = lang_code;
    }
    config.headers["practice-code"] = "DEMO";
    return config;
}, error => {
    return Promise.reject(error);
});
app.config.globalProperties.$axios = axios_global;

// redirect to login on error 401.
axios_global.interceptors.response.use(function (response) {
    return response
  }, error =>  {
    if (error.response.status === 401) {
        commonStore.clearStore();
        window.location.replace("/login?timeout=1")
    }
    else {
      return Promise.reject(error);
    }

})


//Sentry Config
Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount('#app')
