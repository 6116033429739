<template>
  <div class="video-tile position-relative" :class="independent ? 'independent' : ''">
    <template v-if="participant?.video">
      <video autoPlay muted playsInline :srcObject="videoSource"></video>
      <p class="participant-name">
        <ParticipantName :participant="participant" :fallback-username="fallbackUsername" />
      </p>
    </template>

    <template v-else>
      <no-video-tile :participant="participant" :fallback-username="fallbackUsername"></no-video-tile>
    </template>

    <span class="position-absolute mb-2 me-2 bottom-0 end-0">
      <template v-if="!participant?.video">
        <i v-if="!participant?.audio" class="ti ti-microphone-off text-white border bg-secondary rounded-circle fs-3 d-inline-block me-2" />
        <i v-else  class="ti ti-microphone text-white border bg-secondary rounded-circle fs-3 d-inline-block me-2"></i>
      </template>

      
      <i v-if="!participant?.video" class="tf-icons ti ti-video-off text-white border bg-secondary rounded-circle fs-3" />
      <!-- <i v-else class="tf-icons ti ti-video text-white border bg-secondary rounded-circle fs-3" /> -->
    </span> 

  </div>
</template>

<script>
// components
// import CallControls from "./CallControls.vue";
import NoVideoTile from "./NoVideoTile.vue";
import ParticipantName from "@/components/Dailyco/ParticipantName.vue";

// Utils
import { updateSource } from "@/utils/Common.js"

export default {
  name: "VideoTile",
  components: {
    // CallControls,
    NoVideoTile,
    ParticipantName
  },
  props: [
    "participant",
    // "handleVideoClick",
    // "handleAudioClick",
    "independent",
    "fallbackUsername"
  ],
  data() {
    return {
      videoSource: null,
      audioSource: null,
      username: "Guest",
    };
  },
  mounted() {
    this.handleVideo(this.participant);
    // this.handleAudio(this.participant);
  },
  updated() {
    // For later optimization, this can be done more selectively
    // using "track-started" and "track-stopped" events.
    this.handleVideo(this.participant);
    // this.handleAudio(this.participant);
  },
  methods: {    
    // Add srcObject to video element
    async handleVideo() {
      const p = this.participant;

      // If the participant has their video off, early out.
      if (!p?.video) return;

      const track = await p.tracks.video.persistentTrack;
      
      const newStream = updateSource(this.videoSource, track);
      if (newStream) {
        this.videoSource = newStream;
      }
    },

    // Add srcObject to audio element
    /* async handleAudio() {
      const p = this.participant;
      // If the participant is local or has their audio off,
      // early out.
      if (!p || p.local || !p.audio) return;

      const track = await p.tracks.audio.persistentTrack;
      const newStream = updateSource(this.audioSource, track);
      if (newStream) {
        this.audioSource = newStream;
      }
    }*/
  },
};
</script>

<style scoped>
.video-tile {
  margin: 0.8rem;
  height: 250px;
  width: 300px;
  aspect-ratio: 16/9;
}

.video-tile.independent {
    margin: 0;
    width: 100%;
    height: 100%;
}

.video-tile.independent > video {
    border-radius: unset;
}

.video-tile > video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.participant-name {
  position: absolute;
  color: #fff;
  top: 12px;
  right: 12px;
  margin: 0;
}

.video-tile h2 {
  color: #1bebb9;
  margin: 2px 0;
}
.video-tile h3 {
  color: #fff;
  margin: 2px 0;
}
.video-tile p {
  color: #fff;
  margin: 16px 0 0;
  font-size: 12px;
}
</style>
