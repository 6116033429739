import { createRouter, createWebHistory } from 'vue-router'

import PreJoin from '@/views/call/PreJoin.vue';
import EventCreate from "../views/app/events/Create.vue";

import {useCommonStore} from "../stores/common.js";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {
                title: "Home"
            },
            // component: HomeView
            redirect: { 'name': 'login' }
        },
        {
            path: '/login',
            name: 'login',
            meta: {
                title: "Login"
            },
            component: () => import("../views/auth/Login.vue")
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            meta: {
                title: "Forgot Password"
            },
            component: () => import("../views/auth/forgot-password.vue")
        },
        {
            path: '/reset/:jwt_token',
            name: 'reset-password',
            meta: {
                title: "Reset Password"
            },
            component: () => import("../views/auth/reset-password.vue")
        },
        {
            path: '/app', // has app main layout set
            name: 'app',
            meta : {
                requiresAuth: true
            },
            redirect: { 'name': 'events' },
            component: () => import("../layouts/MainLayout.vue"),
            children: [
                // Users page
                {
                    path: 'users',
                    meta: {
                        title: "Users",
                        requiresAuth: true
                    },
                    name: 'users',
                    redirect: { 'name': "users-list" },
                    component: () => import("../views/app/users/App.vue"),
                    children: [
                        {
                            path: 'list',
                            name: 'users-list',
                            meta: {
                                title: "Users List",
                                requiresAuth: true
                            },
                            component: () => import("../views/app/users/List.vue")
                        },
                        {
                            path: 'add',
                            name: 'users-add',
                            meta: {
                                title: "Add Agent",
                                requiresAuth: true
                            },
                            component: () => import("../views/app/users/Create.vue")
                        },
                        {
                            path: 'edit',
                            name: 'users-edit',
                            meta: {
                                title: "Edit Agent",
                                requiresAuth: true
                            },
                            component: () => import("../views/app/users/Create.vue")
                        },

                    ],

                },
                // contacts page
                {
                    path: 'contacts',
                    meta: {
                        title: "Contacts",
                        requiresAuth: true
                    },
                    name: 'contacts',
                    redirect: { 'name': "contacts-list" },
                    component: () => import("../views/app/contacts/App.vue"),
                    children: [
                        {
                            path: 'list',
                            name: 'contacts-list',
                            meta: {
                                title: "Contacts List",
                                requiresAuth: true
                            },
                            component: () => import("../views/app/contacts/List.vue")
                        },
                        {
                            path: 'add',
                            name: 'contacts-add',
                            meta: {
                                title: "Add Contact",
                                requiresAuth: true
                            },
                            component: () => import("../views/app/contacts/Create.vue")
                        },
                        {
                            path: 'edit',
                            name: 'contacts-edit',
                            meta: {
                                title: "Edit Contact",
                                requiresAuth: true
                            },
                            component: () => import("../views/app/contacts/Create.vue")
                        },
                        {
                            path: ':contact_id/events',
                            name: 'contacts-events',
                            meta: {
                                title: "Events",
                                requiresAuth: true
                            },
                            component: () => import("../views/app/contacts/Events.vue")
                        },

                    ]
                },
                // events page
                {
                    path: 'events',
                    meta: {
                        title: "Events",
                        requiresAuth: true
                    },
                    name: 'events',
                    redirect: { 'name': "events-list" },
                    component: () => import("../views/app/events/App.vue"),
                    children: [
                        {
                            path: 'list',
                            name: 'events-list',
                            meta: {
                                title: "Events List",
                                requiresAuth: true
                            },
                            component: () => import("../views/app/events/List.vue")
                        },
                        {
                            path: 'add',
                            name: 'events-add',
                            meta: {
                                title: "Add Event",
                                requiresAuth: true
                            },
                            component: EventCreate
                        },
                        {
                            path: 'edit',
                            name: 'events-edit',
                            meta: {
                                title: "Edit Event",
                                requiresAuth: true
                            },
                            component: EventCreate
                        },

                    ]
                },

                // Profile page
                {
                    path: 'profile',
                    meta: {
                        title: "Profile",
                        requiresAuth: true
                    },
                    name: 'profile',
                    redirect: { 'name': "calendar-connections" },
                    component: () => import("../views/app/profile/App.vue"),
                    children: [
                        {
                            path: 'calendar-connections',
                            name: 'calendar-connections',
                            meta: {
                                title: "Calendar Connections",
                                requiresAuth: true
                            },
                            component: () => import("../views/app/profile/CalendarConnections.vue")
                        },
                    ]
                }
            ]
        },
        {
            path: "/pre-join",
            name: "pre-join",
            meta: { title: "Join Meeting" },
            component: PreJoin
        },
        {
            path: "/event/:id",
            name: "meeting",
            meta: { title: "Join Meeting" },
            component: () => import("@/views/call/Call.vue")
        },

        {
            // MUST KEEP THIS PATH AT END ONLY.
            path: "/:pathMatch(.*)*",
            name: "not-found",
            component: () => import("../views/errors/NotFound.vue"),
        },

    ]
})

router.afterEach((to, from) => {
    document.title = to.meta.title ? to.meta.title +" - AnsweringLegal" : "Portal - AnsweringLegal";
});


// Route Guard for protected routes.
router.beforeEach(async (to, from, next) => {
    const commonStore = useCommonStore();

    // if loggedin user visits login page again
    if(commonStore.state.isLoggedIn == true && to.name == "login") {
        next({name : "app"});
        return ;
    }

    //only allow loggedIn user to access protected route which has RequiresAuth set to true in meta.
    if(to.meta && to.meta.requiresAuth == true && to.name != "login") {

        if(commonStore.state.isLoggedIn == true ) {
            next();
        }
        else {

            // if user visits specific path and not logged in then,
            // pass the full_path as query parameter "after" to login page.
            // login page will check if "after" query parameter exists.
            next({"name" : "login",
                query : {
                    after : encodeURI(to.fullPath),
                }
            })
        }
    }
    else {
        next();
    }

})

export default router