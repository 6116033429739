import moment from "moment-timezone"

export function getTimezonesWithOffset() {
    const zones = moment.tz.names();
    const result = [];
  
    for (const zone of zones) {
      try {
        const offsetMinutes = moment().tz(zone).utcOffset();
        const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
        const offsetMinutesRemaining = Math.abs(offsetMinutes % 60);


        const sign = offsetMinutes >= 0 ? '+' : '-';

        const offsetHoursString = offsetHours.toString().padStart(2, '0');
        const offsetMinutesString = offsetMinutesRemaining.toString().padStart(2, '0');
  
        const offsetString = `${sign}${offsetHoursString}:${offsetMinutesString}`;

        const timeZone = `${zone} (GMT ${offsetString})`
        
        result.push({
          "timezone" : zone,
          "timezone_with_offset" : timeZone 
        });
        
      } catch (e) {
        //
      }
    }
  
    return result;
}


export function getTimezones() {
    const zones = moment.tz.names();
    return zones;
}