export const SERVER_URL = "https://lvc.answeringlegal.com:4055"
//export const SERVER_URL = "https://videoapp.doctodoor.com:5500"

export const API_URL = SERVER_URL + "/api/service/v1/";

export const HTTP_STATUS = {
    OK: 200,
    CLIENT_ERROR: 400,
    SERVER_ERROR: 500
};

export const BG_FILTER = "video-bg-filter"
